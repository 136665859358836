/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { load as FingerprintJSLoad } from '@fingerprintjs/fingerprintjs';
import React, { useEffect } from 'react';
import AppStore from '../../assets/appstore_logos/AppStore.png';
import MicrosoftStore from '../../assets/appstore_logos/Microsoft.png';
import PlayStore from '../../assets/appstore_logos/PlayStore.png';
import ResponsiveDesign from '../../assets/appstore_logos/responsiveDesign.webp';
import BallardFoodBank from '../../assets/partner_logos/BallardFoodBank.png';
import FamilyWorksFoodBank from '../../assets/partner_logos/FamilyWorksFoodBank.jpg';
import KrogerZeroHungerZeroWaste from '../../assets/partner_logos/Kroger_zhzw.png';
import Logo from '../../assets/platform_logos/Logo.png';

import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import {
  Bars3Icon,
  BellIcon,
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  UserIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  BellIcon as BellIconSolid,
  CheckCircleIcon,
  ShoppingCartIcon as ShoppingCartIconSolid,
  UserIcon as UserIconSolid,
} from '@heroicons/react/24/solid';
import { Bell, Inbox, InboxContent, Notification, Notifications, Preferences } from '@novu/react';
import { NovuProvider, useNotifications } from '@novu/react/hooks';
import { Link, NavLink, useLocation, useNavigate, useNavigation, useParams } from '@remix-run/react';
import { createHooks } from '@wundergraph/swr';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { truncate } from 'fs';
import { Fragment, useState } from 'react';
import { z, ZodIntersection } from 'zod';
import { createClient, Operations } from '../../../components/generated/client';
import { strapi_UserProfileInput } from '../../../components/generated/models';
import { useAuth, useUser } from '../../../lib/wundergraph';
import Account from './Account';
import AdminMenu from './AdminMenu';
import BellButton from './BellButton';
import CartMenu from './CartMenu';
import CustomNotifications from './CustomNotifications';
import MainMenu from './MainMenu';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header({
  signupUrl,
  user,
  userProfile,
  minioReverseProxyURL,
  deviceTokenFetcher,
  novuApplicationIdentifier,
  novuAPIURL,
  novuSocketURL,
}) {
  const [open, setOpen] = useState(false)
  const [notificationOpen, setNotificationOpen] = useState(false)
  const [profileOpen, setProfileOpen] = useState(false)
  const [cartOpen, setCartOpen] = useState(false)
  const location = useLocation()
  const { login, logout } = useAuth()
  const { state } = useNavigation()
  const params = useParams<{ vendorIdentifier?: string }>()
  const navigate = useNavigate()
  const [fpHash, setFpHash] = React.useState('');

  const [isAdminPage, setIsAdminPage] = useState(
    location.pathname.includes('/administration')
  );

  const client = createClient();
  const { useMutation } = createHooks<Operations>(client);

  const {
    data: updatedUserProfile,
    error,
    trigger,
    isMutating,
  } = useMutation({
    operationName: 'StrapiCustom/UpdateUserProfile',
  })

  React.useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJSLoad();

      const { visitorId } = await fp.get();

      setFpHash(visitorId);
    };

    setFp();
  }, []);

  useEffect(() => {
    setIsAdminPage(location.pathname.includes('/administration'))
  }, [location.pathname])

  const toggleMainMenuOpenState = () => {
    setOpen(!open)
  }

  const toggleNotificationOpenState = () => {
    setNotificationOpen(!notificationOpen)
  }

  const toggleProfileOpenState = () => {
    setProfileOpen(!profileOpen)
  }

  const [deviceTokenGenerating, setDeviceTokenGenerating] = useState(false)

  const [notificationCount, setNotificationCount] = useState(0)

  const appearance = {
    elements: {
      bellIcon: "h-6 w-6 text-white",
      bellContainer: "h-6 w-6",
      notification: "bg-white shadow-sm hover:shadow-md hover:bg-gray-50",
      inbox__popoverContent: "fixed top-0 right-0 h-full w-80 text-sm z-20 rounded-none overscroll-none",
      popoverContent: "fixed top-0 right-0 h-full w-80 text-sm z-20 rounded-none",
      inboxContent: "fixed top-0 right-0 h-full w-80 text-sm z-20 rounded-none",
      inboxStatus__title: "text-lg font-semibold",
      inboxStatus__dropdownContent: "text-sm",
      preferencesHeader__title: "text-lg font-semibold",
      workflowLabel: "text-sm font-semibold",
      channelLabel: "text-sm font-semibold",
      notificationDot: "left-6 top-5 mt-0.5",
      notificationRead__button: "",
      notificationArchive__button: "",
    },
    variables: {
      colorPrimary: "#E07A5F",
      // colorBackground: "#FFFFFF"
    }
  }

  const firebaseConfig = {
    apiKey: "AIzaSyASA-eSYaolNY2NvRTi9g_v3KP-K08Qh9E",
    authDomain: "leftovers-today-1718176623761.firebaseapp.com",
    projectId: "leftovers-today-1718176623761",
    storageBucket: "leftovers-today-1718176623761.firebasestorage.app",
    messagingSenderId: "1088139253835",
    appId: "1:1088139253835:web:233ca9a9e320ac0c5695ce",
    measurementId: "G-WLTKDNSJ08"
  }

  async function requestNotificationPermission() {
    try {
      if (window.Notification.permission !== 'granted') {
        // Get permission to send notifications
        const permission = await window.Notification.requestPermission();
        Promise.resolve(permission)

        // If permission is granted then generate a new token
        if (permission === 'granted' && typeof window !== 'undefined') {
          setDeviceTokenGenerating(true)

          // Get the device token
          const app = initializeApp(firebaseConfig)
          const messaging = getMessaging(app)

          await getToken(messaging, {
            vapidKey:
              "BBvFMC6DbLOtwt5ZYLBGzrwFNV-mDOcScfOTmjh9PZPCFeYiZJi4GerqZYTaLH37a7PBQ150jYzBwwFF6cR0ms8",
          })
            .then(async (currentToken) => {
              if (currentToken) {
                console.log('Device Token: ', currentToken)
                let deviceTokens = {}
                if (userProfile.data?.strapi_userProfiles?.data[0].attributes?.pushDeviceTokens != undefined) {
                  deviceTokens = userProfile.data?.strapi_userProfiles?.data[0].attributes?.pushDeviceTokens
                }

                deviceTokens[fpHash] = currentToken;

                const userProfileInput: strapi_UserProfileInput = {
                  pushDeviceTokens: deviceTokens
                };

                // Trigger the mutation to update the user profile from client side because remix root action
                // is not working as expected
                const tokenUpdateResult = await trigger({
                  id: userProfile.data.strapi_userProfiles.data[0].id,
                  strapi_UserProfileInput: userProfileInput
                })

              }
            })
          setDeviceTokenGenerating(false)
        }
        return false
      }
    } catch (error) {
      console.error('Error requesting notification permission', error);
    }
  }

  return (
    <>
      <div className="bg-forestGreen-950 focus-outline-none outline-none" suppressHydrationWarning>
        <div className="mx-auto h-14 max-w-6xl">
          <nav aria-label="Top" className="mx-auto px-4">
            <div className="border-gray-900">
              <div className="flex h-14 items-center justify-between">
                {/* Hamburger main menu */}
                <div className="flex flex-1 items-center focus-outline-none outline-none ">
                  <button
                    type="button"
                    className="text-white-400 -ml-1 rounded-md bg-forestGreen-950 p-2"
                    onClick={() => (state !== 'loading' ? setOpen(true) : '')}
                  >
                    <span className="sr-only">Open menu</span>
                    <Bars3Icon
                      className="h-7 w-7 stroke-white stroke-2"
                      aria-hidden="true"
                    />
                  </button>
                </div>

                {/* Hamburger main menu */}
                <Transition show={open}>
                  <Dialog className="relative z-30" onClose={setOpen}>
                    <TransitionChild
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity" />
                    </TransitionChild>

                    <div className="fixed inset-0 z-30 flex overflow-hidden">
                      <div className="pointer-events-none fixed inset-y-0 left-0 flex min-w-80 max-w-80">
                        <TransitionChild
                          enter="transition ease-in-out duration-300 transform"
                          enterFrom="-translate-x-full"
                          enterTo="translate-x-0"
                          leave="transition ease-in-out duration-300 transform"
                          leaveFrom="translate-x-0"
                          leaveTo="-translate-x-full"
                        >
                          <DialogPanel className="pointer-events-auto">
                            <div className="flex h-full flex-col rounded-r-3xl pt-14 shadow-xl">
                              <div className="absolute top-0 h-14 w-full rounded-tr-3xl bg-forestGreen-950 px-4 py-4">
                                <div className="flex items-start justify-between">
                                  <DialogTitle className="text-md px-2 font-bold leading-6 text-white">
                                    {isAdminPage ? 'Administration Menu' : 'Menu'}
                                  </DialogTitle>
                                  <div className="ml-3 flex h-7 items-center">
                                    <button
                                      type="button"
                                      className="relative -m-2 p-2 text-white hover:text-gray-100"
                                      onClick={() => setOpen(false)}
                                    >
                                      <span className="absolute -inset-0.5" />
                                      <span className="sr-only">Close panel</span>
                                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {isAdminPage ? (
                                <AdminMenu toggleOpenState={toggleMainMenuOpenState} />
                              ) : (
                                <MainMenu toggleOpenState={toggleMainMenuOpenState} user={user} />
                              )}
                            </div>
                          </DialogPanel>
                        </TransitionChild>
                      </div>
                    </div>
                  </Dialog>
                </Transition>

                {/* Logo */}
                {state === 'loading' || deviceTokenGenerating ? (
                  <>
                    <div className="fixed top-1 inset-x-1 justify-center align-middle">
                      <div className="flex flex-row space-x-2 justify-center align-middle">
                        <div className="h-12 w-12 animate-spin rounded-full border-b-4 border-t-4 border-logoColor-500"></div>
                      </div>
                    </div>
                  </>) : ''
                }

                <NavLink
                  // viewTransition
                  to={isAdminPage && params.vendorIdentifier ? `/administration/${params.vendorIdentifier}/overview` : '/'} className="flex focus-outline-none outline-none">
                  {/* <Link to="/" className="flex"> */}
                  <span className="sr-only">Leftovers.today, Inc.</span>
                  <img className="h-10 w-auto" src={Logo} alt="" />
                </NavLink>

                {/* Notifications menu */}
                <div className="flex flex-1 items-center justify-end focus-outline-none outline-none ">

                  <NovuProvider
                    applicationIdentifier={novuApplicationIdentifier}
                    subscriberId={user?.userId}
                    backendUrl={novuAPIURL}
                    socketUrl={novuSocketURL}
                  // routerPush={(path: string) => navigate(path)}
                  // appearance={appearance}
                  >
                    {user && (
                      <button
                        onClick={() => (fpHash ? (requestNotificationPermission().then(() => {
                          setNotificationOpen(true)
                          if (navigator.serviceWorker.controller) {
                            navigator.serviceWorker.controller.postMessage({ 'BADGE_UPDATE': 0 });
                          }
                        })) : '')}
                        disabled={notificationOpen}
                      >
                        <BellButton notificationCount={notificationCount} setNotificationCount={setNotificationCount} />
                      </button>
                    )}

                    {/* Notifications */}

                    <Transition show={notificationOpen}>
                      <Dialog className="relative z-30" onClose={setNotificationOpen}>
                        <TransitionChild
                          enter="ease-in-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in-out duration-300"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity" />
                        </TransitionChild>
                        Í
                        <div className="fixed inset-0 overflow-hidden">
                          <div className="pointer-events-none fixed inset-y-0 right-0 flex min-w-80 max-w-80">
                            <TransitionChild
                              enter="transform transition ease-in-out duration-300"
                              enterFrom="translate-x-full"
                              enterTo="translate-x-0"
                              leave="transform transition ease-in-out duration-300"
                              leaveFrom="translate-x-0"
                              leaveTo="translate-x-full"
                            >
                              <DialogPanel className="pointer-events-auto">
                                <div className="flex h-full flex-col rounded-l-3xl pt-14 shadow-xl bg-white">
                                  <div className="absolute top-0 h-14 w-full rounded-tl-3xl bg-forestGreen-950 px-4 py-4">
                                    <div className="flex items-start justify-between">
                                      <DialogTitle className="text-md px-2 font-bold leading-6 text-white">
                                        Notifications
                                      </DialogTitle>

                                      <div className="ml-3 flex h-7 items-center">
                                        <button
                                          type="button"
                                          className="relative -m-2 p-2 text-white hover:text-gray-100"
                                          onClick={() => setNotificationOpen(false)}
                                        >
                                          <span className="absolute -inset-0.5" />
                                          <span className="sr-only">Close panel</span>
                                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Main */}
                                  <div className='overflow-y-auto'>
                                    <CustomNotifications
                                      notificationOpen={notificationOpen}
                                      toggleNotificationOpenState={toggleNotificationOpenState}
                                      notificationCount={notificationCount}
                                      setNotificationCount={setNotificationCount}
                                    />
                                  </div>
                                </div>
                              </DialogPanel>
                            </TransitionChild>
                          </div>
                        </div>
                      </Dialog>
                    </Transition>
                  </NovuProvider>

                  <button
                    onClick={() => {
                      state !== 'loading' ? setProfileOpen(true) : ''
                    }}
                    className="p-2 text-white lg:p-2 focus-outline-none outline-none "
                  >
                    {user ? (
                      <UserIconSolid className="h-6 w-6" aria-hidden="true" />
                    ) : (
                      <UserIcon className="h-6 w-6" aria-hidden="true" />
                    )}
                  </button>

                  {/* Profile menu transition */}
                  <Transition show={profileOpen}>
                    <Dialog className="relative z-30" onClose={setProfileOpen}>
                      <TransitionChild
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity" />
                      </TransitionChild>
                      <div className="fixed inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex min-w-80 max-w-80">
                          <TransitionChild
                            enter="transform transition ease-in-out duration-300"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-300"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                          >
                            <DialogPanel className="pointer-events-auto">
                              <div className="flex h-full flex-col rounded-l-3xl pt-14 shadow-xl min-w-80 max-w-80">
                                <div className="absolute top-0 h-14 w-full rounded-tl-3xl bg-forestGreen-950 px-4 py-4">
                                  <div className="flex items-start justify-between">
                                    <DialogTitle className="text-md px-2 font-bold leading-6 text-white">
                                      Account
                                    </DialogTitle>

                                    <div className="ml-3 flex h-7 items-center">
                                      <button
                                        type="button"
                                        className="relative -m-2 p-2 text-white hover:text-gray-100"
                                        onClick={() => setProfileOpen(false)}
                                      >
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">Close panel</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {/* Main */}
                                <Account
                                  toggleOpenState={toggleProfileOpenState}
                                  signupUrl={signupUrl}
                                  userProfile={userProfile}
                                  minioReverseProxyURL={minioReverseProxyURL}
                                  user={user}
                                />
                              </div>
                            </DialogPanel>
                          </TransitionChild>
                        </div>
                      </div>
                    </Dialog>
                  </Transition>
                </div>
              </div>
            </div>
          </nav>
        </div >
      </div >
    </>
  )
}
