/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import { BellIcon } from '@heroicons/react/24/outline';
import { BellIcon as BellIconSolid } from '@heroicons/react/24/solid';
import { useCounts } from '@novu/react/hooks';
import { useBadgeApi, usePermission } from '@remix-pwa/client';
import React, { useEffect } from 'react';

export default function BellButton({ notificationCount, setNotificationCount }) {
  const { counts } = useCounts({ filters: [{ read: false }] })
  const { badgeCount, setBadgeCount } = useBadgeApi()

  useEffect(() => {
    async function setBadge(count) {
      try {
        await navigator.setAppBadge(count)

      } catch (error) {
        console.error('Error setting badge', error);
      }
    }

    setNotificationCount(counts && counts[0].count)
    setBadge(counts && counts[0].count)

  }, [counts, setBadgeCount, setNotificationCount])

  return (
    <div className="relative min-w-8 max-w-8 place-content-center justify-center rounded-md bg-darkForestGreen-950 text-center"
    >

      {notificationCount > 0 && (
        <div className="absolute right-0 -top-2 max-h-5 min-h-5 min-w-5 max-w-5 justify-center rounded-full bg-warmTerracota-700 text-center pt-0.5 px-px">
          <p className="text-xs font-semibold text-white">{counts && counts[0].count}</p>
        </div>
      )}

      <div className="">
        {counts && counts[0].count > 0 ? (
          <BellIconSolid className="h-6 w-6 text-white" aria-hidden="true" />)
          :
          (<BellIcon
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />)}
      </div>
    </div>
  )
}